export default {
  0: {
    name: 'Новый',
    badge: 'secondary',
  },
  1: {
    name: 'Принят',
    badge: 'secondary',
  },
  4: {
    name: 'Готов',
    badge: 'warning',
  },
  5: {
    name: 'Отправлен',
    badge: 'primary'
  },
  2: {
    name: 'Выполнен',
    badge: 'success',
  },
  3: {
    name: 'Удален',
    badge: 'danger',
  },
};
