<template>
  <div v-if="loadedOrders">
    <CRow class="mb-4">
      <CCol>

        <CLink v-for="tab in tabs" class="btn btn-primary btn-ghost-primary mr-2"
               v-bind:class="{active: tabActiveKey === tab.scope}"
               :to="{path: '', query: {scope: tab.scope}}">
          {{ tab.label }}
        </CLink>
      </CCol>

    </CRow>

    <CCard>
      <CCardHeader>
        <CIcon name="cil-cart"/>
        <strong> {{ tabs[tabActiveKey].title }} </strong>
      </CCardHeader>
      <CCardBody>

        <CForm v-on:submit.prevent="loadDeliveries()">
          <CRow>
            <CCol lg="5">
              <CInput v-model="filter.search" type="text" name="search"
                      placeholder="Номер заказа, Телефон, Имя...">
                <template #label><span class="text-muted">Поиск по Номеру, Телефону, Имени:</span></template>
              </CInput>
            </CCol>
            <CCol lg="5">
              <CInput v-model="filter.date" type="date" name="date" placeholder="Сортировка по дате заказа">
                <template #prepend-content>
                  <CIcon name="cil-calendar"/>
                </template>
                <template #label><span class="text-muted">Поиск по дате заказа:</span></template>
              </CInput>
            </CCol>
            <CCol lg="2" class="text-right">
              <CButton type="submit" color="primary">Найти</CButton> &nbsp;
              <CButton type="button" v-on:click.prevent="clearFilter()" color="secondary">Сбросить</CButton>
            </CCol>
          </CRow>

        </CForm>
        <hr>

        <CDataTable
          :items="loadedOrders"
          :fields="loadedOrdersFields"
          :striped="true"
          :noItemsView="{noResults: 'По результатов', noItems: 'Нет доставок'}"
          :loading="loading"
        >
          <template #delivery="{item}">
            <td>
              <strong>{{ item.delivery.date }} &mdash; {{ item.delivery.time }}</strong>
            </td>
          </template>
          <template #id="{item}">
            <td>
              <CLink :to="{ path: './view/' + item.id }">Заказ №<strong>{{ item.code }}</strong></CLink>
              ({{ item.id }}) {{ item.consumer.name }}
            </td>
          </template>
          <template #status="{item}">
            <td>
              {{ item.price.total }} руб.<br>
              <CBadge :color="statuses[item.status].badge">{{ statuses[item.status].name }}</CBadge>
            </td>
          </template>
          <template #actions="{item}">
            <td class="text-right">
              <CButton color="success" :disabled="clickedDoneIds.includes(item.id)"
                       v-if="item.status !== 2 && item.status !== 3" @click="deliveryDoneModalShow(item.id)">
                <CIcon name="cil-check-circle"></CIcon>
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          v-show="pagination"
          :active-page.sync="activePage"
          :pages="pages"
        />
      </CCardBody>
    </CCard>
    <CModal
      title="Вы уверены?"
      size="sm"
      :centered=true
      :show.sync="deliveryDoneModal"
      @close="deliveryDoneCancel(deliveryDoneId)"
    >
      <template #body-wrapper><div></div></template>
      <template #footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="deliveryDone(deliveryDoneId)"
        >
          Да
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="deliveryDoneCancel(deliveryDoneId)"
        >
          Отмена
        </button>
      </template>
    </CModal>
  </div>
  <div v-else>
    <CRow class="mb-4">
      <CCol class="p-3 align-content-center align-items-center text-center">
        <CSpinner :grow="true" size="lg" color="primary"></CSpinner>
        Загрузка...
      </CCol>
    </CRow>
  </div>
</template>

<script>
import statuses from './order_statuses'

export default {
  name: 'DeliveryList',
  data() {
    return {
      deliveryDoneModal: false,
      deliveryDoneId: null,
      clickedDoneIds: [],
      statuses: statuses,
      tabActiveKey: this.$route.query.scope,
      activePage: parseInt(this.$route.query.page ? this.$route.query.page : 1),
      filter: {
        search: '',
        date: '',
      },
      tabs: {
        active: {
          scope: 'active',
          label: 'Активные',
          title: 'Все активные доставки'
        },
        done: {
          scope: 'done',
          label: 'Выполненные',
          title: 'Выполненные доставки'
        },
        all: {
          scope: 'all',
          label: 'Все',
          title: 'Выполненные доставки'
        },
      },
      loadedOrders: null,
      loadedOrdersFields: [
        {
          key: 'delivery',
          label: 'Доставка'
        },
        {
          key: 'id',
          label: 'Заказ'
        },
        {
          key: 'status',
          label: 'Статус'
        },
        {
          key: 'actions',
          label: ''
        },
      ],
      pagination: false,
      pages: 1,
      loading: false,
    }
  },
  watch: {
    activePage: {
      immediate: true,
      handler() {
        let page = parseInt(this.$route.query.page)
        if (this.activePage !== page) {
          this.$router.push({query: {...this.$route.query, page: this.activePage}});
        }
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.tabActiveKey = this.$route.query.scope ? this.$route.query.scope : 'active';
        this.loadDeliveries();
      }
    }
  },
  methods: {
    async loadDeliveries() {
      this.loading = true;

      let uri = '/deliveries/' + this.tabActiveKey + '?page=' + this.activePage;
      if (this.filter.search !== '') {
        uri += '&filter[search]=' + this.filter.search;
      }

      if (this.filter.date !== '') {
        uri += '&filter[date]=' + this.filter.date;
      }

      let response = await this.$http.get(uri);
      this.loadedOrders = response.data.data;
      this.pages = response.data.meta.total_pages;
      this.pagination = (response.data.meta.total_pages > 1);
      this.loading = false;
      if (this.tabActiveKey === 'active') {
        this.$store.commit('set', ['countDeliveries', response.data.meta.total]);
      }
    },
    async deliveryDoneModalShow(id) {
      this.deliveryDoneModal = true;
      this.deliveryDoneId = id;
    },
    async deliveryDoneCancel() {
      this.deliveryDoneModal = false;
      this.deliveryDoneId = null;
    },
    async deliveryDone(id) {
      this.clickedDoneIds.push(id);
      this.deliveryDoneModal = false;
      this.deliveryDoneId = null;

      await this.$http.post('/deliveries/delivered/' + id);
      await this.loadDeliveries();

      let index = this.clickedDoneIds.indexOf(id);
      if (index > -1) {
        this.clickedDoneIds.splice(index, 1);
      }
    },
    async clearFilter() {
      this.filter = {
        search: '',
        date: '',
      };
      await this.loadDeliveries();
    }
  }
}
</script>
